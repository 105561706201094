import { defineStore } from "pinia";

export const useAppSidebarMenuStore = defineStore({
  id: "appSidebarMenu",
  state: () => {
    return [
      // keeping for future reference
      // {
      //   text: "Menu",
      //   is_header: true,
      // },
      {
        url: "/",
        icon: "fa fa-heart-pulse",
        text: "Dashboard",
      },
      // {
      //   url: "/parking/lots",
      //   icon: "fa fa-building",
      //   text: "Locations",
      // },
      {
        url: "/front-desk",
        icon: "fa-solid fa-building-circle-check",
        text: "Front desk",
      },
      {
        url: "/reporting",
        icon: "fa fa-database",
        text: "Reporting",
      },
      
      // keeping for future reference
      // {
      //   is_divider: true,
      // },
    ];
  },
});
