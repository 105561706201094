import { useAppUserStore } from "@/stores/app-user";

export const beforeEnterPartners = () => {
  const appUser = useAppUserStore();

  // Redirect to parking sessions if user does not have permission to manage partners.
  if (!appUser?.identityPermissions?.["parking:managePartners"]) {
    return "/";
  }
};
