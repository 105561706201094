import { createRouter, createWebHistory } from "vue-router";
import authGuard from "@/guards/authGuard";
import { beforeEnterPartners } from "@/router/navigationGuards";

const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  routes: [
    // Authentication
    {
      path: "/login",
      name: "Login",
      component: () => import("@/views/Login.vue"),
      beforeEnter: authGuard,
    },
    // Authenticated routes
    {
      path: "/",
      name: "Dashboard",
      component: () => import("@/views/Dashboard.vue"),
      beforeEnter: authGuard,
    },
    {
      path: "/front-desk",
      name: "Front desk",
      component: () => import("@/views/FrontDesk.vue"),
      beforeEnter: authGuard,
    },
    {
      path: "/parking/lots",
      name: "Parking Lots",
      component: () => import("@/views/PageParkingLots.vue"),
      beforeEnter: authGuard,
    },
    {
      path: "/parking/lots/:lotId",
      component: () => import("@/views/PageParkingLot.vue"),
      beforeEnter: authGuard,
      props: true,
    },
    {
      path: "/reporting",
      name: "Reporting",
      component: () => import("@/views/PageReporting.vue"),
      redirect: { name: "Run Report" },
      beforeEnter: authGuard,
      children: [
        {
          path: "run",
          name: "Run Report",
          component: () => import("@/views/PageRunReport.vue"),
        },
        {
          path: "schedule",
          name: "Schedule Report",
          component: () => import("@/views/PageScheduleReport.vue"),
        },
      ],
    },
    //partners
    {
      path: "/partners/new",
      name: "Add Partner",
      component: () => import("@/views/partners/PagePartnerNew.vue"),
      beforeEnter: beforeEnterPartners,
    },
    {
      path: "/partners/:partnerId",
      name: "View Partner",
      component: () => import("@/views/partners/PagePartner.vue"),
      beforeEnter: beforeEnterPartners,
    },
    {
      path: "/partners/:partnerId/manage",
      name: "Manage Partner",
      component: () => import("@/views/partners/PagePartnerManage.vue"),
      props: true,
      beforeEnter: beforeEnterPartners,
    }
  ],
});

export default router;
